import {Component, OnInit} from '@angular/core';

/**
 * Site Pages Component
 */
@Component({
    selector: 'app-sitepages',
    templateUrl: './sitepages.component.html',
    styles: []
})
export class SitepagesComponent implements OnInit {

    /**
     * @ignore
     */
    constructor() {}

    /**
     * @ignore
     */
    ngOnInit() {}
}
