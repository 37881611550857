import {Action} from '@ngrx/store';
import {FineModel} from './fine.model';

export const SET_FINE = '[FINE DATA] Set Fine data';
export const UNSET_FINE = '[FINE DATA] Unset Fine data';

export class SetFineAction implements Action {
    readonly  type = SET_FINE;

    constructor (public fine: FineModel) {}
}

export class UnsetFineAction implements Action {
    readonly  type = UNSET_FINE;
}

export type actions = SetFineAction | UnsetFineAction;
