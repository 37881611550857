import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {TrackingService} from "./tracking.service";
import {LangISOStore} from "./stores/langiso.store";

import {appConfig} from 'src/app/config/config';

/**
 * Lang Service
 *
 * @author Fran Aragon
 *
 * Manage lang service of application
 */
@Injectable({
    providedIn: 'root'
})
export class LangService {

    /**
     * language
     */
    private lang: string;

    private nameSess: string;

    /**
     * @ignore
     */
    constructor(@Inject(DOCUMENT) private _document,
                private translate: TranslateService,
                private trackingService: TrackingService) {

        this.nameSess = appConfig.lang_session;
        this.loadSessionLang();
    }

    /**
     * Get Browser Language and set by default
     */
    loadDefaultLang() {
        let browserlang = navigator.language;
        let langCode = browserlang.split("-")[0];
        this.translate.setDefaultLang(this.getCodeLang(langCode));
        this.lang = this.getCodeLang(langCode);
        this.setLangHTML();
        if(this.trackingService.getPermissionTracking() == 'all' || this.trackingService.getPermissionTracking() == 'preferences') {
            this.setSessionLang();
        }
    }

    /**
     * Switch Language
     */
    switchLang( lang: string) {
        this.translate.use(lang);
        this.lang = lang;
        this.setLangHTML();
        this.setSessionLang();
        if(this.trackingService.getPermissionTracking() == 'all' || this.trackingService.getPermissionTracking() == 'preferences') {
            this.setSessionLang();
        }
    }

    /**
     * Get Language
     */
    getLang() {
        if(localStorage.getItem(this.nameSess)) {
            this.lang = localStorage.getItem(this.nameSess);
            this.translate.use(this.lang);
            return this.lang;
        } else {
            this.loadDefaultLang();
            return this.lang;
        }
    }

    /**
     * Get a code of a language string iso code for to send TPV
     */
    getLangTPV() {
        if(localStorage.getItem(this.nameSess)) {
            this.lang = localStorage.getItem(this.nameSess);
            for(let lang of LangISOStore) {
                if(lang.lang == this.lang) {
                    return lang.isoCode;
                }
            }
            return '002';
        } else {
            this.loadDefaultLang();
            for(let lang of LangISOStore) {
                if(lang.lang == this.lang) {
                    return lang.isoCode;
                }
            }
            return '002';
        }
    }

    /**
     * Load Language
     */
    loadSessionLang() {
        if(localStorage.getItem(this.nameSess)) {
            this.lang = localStorage.getItem(this.nameSess);
            this.translate.use(this.lang);
            this.setLangHTML();
        } else {
            this.loadDefaultLang();
        }
    }

    /**
     * Set Language option in local storage
     */
    setSessionLang() {
        localStorage.setItem(this.nameSess, this.lang);
    }

    /**
     * Set Language in html
     */
    setLangHTML() {
        this._document.getElementById('lang-pyf').setAttribute('lang', this.lang);
    }

    /**
     * Get a code of a language string iso code
     *
     * @param {string} lang
     * @returns {string} ISO Code of Lang
     */
    getCodeLang(lang: string) {
        if (LangISOStore.find(langIso => langIso.lang === lang)) {
            return 'pt';
        } else {
            return 'pt';
        }
    }
}
