import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  itemsPerSlide = 4;
  singleSlideOffset = true;
  noWrap = true;
  showIndicators = false;
  interval = 3000;
  noPause = true;
  activeSlideIndex = 0;
  slides = [
    {image: 'assets/images/logos-carousel/logo-carousel-covey.png'},
    {image: 'assets/images/logos-carousel/logo-carousel-nizacars.png'},
    {image: 'assets/images/logos-carousel/logo-carousel-sixt.png'},
    {image: 'assets/images/logos-carousel/logo-carousel-europcar.png'}
  ];
  constructor() { }

  ngOnInit() {
    if (window.screen.width < 769) {
      this.itemsPerSlide = 1;
    }
  }

}
