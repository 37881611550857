import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {TrackingService} from "../tracking.service";
import {AuthService} from "../auth.service";

/**
 * @deprecated
 *
 * TypeaccessloginGuard
 *
 * @author Fran Aragon
 *
 * Using like a middleware method to send type access login event sms or email IMPORTANT -> NOT USED GUARD DEPRECATED IN PROJECT
 */
@Injectable({
    providedIn: 'root'
})
export class TypeaccessloginGuard implements CanActivate {

    /**
     * @ignore
     */
    constructor(private trackingService: TrackingService) { }

    /**
     * Using like a middleware method to send type access login event sms or email
     * checks if previous path route contains pyf, example -> domain.com/pyf/983948
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

       /* const hash  =  route.paramMap.get('hash');
        this.trackingService.setHash(hash);
        let url_split = state.url.split("/");
        let section_url = url_split[url_split.length - 2];
        if (section_url == 'pyf') {
            this.typeAccessEvent('sms');
        } else {
            this.typeAccessEvent('email');
        }*/

        return true;
    }

    /**
     * Captures type access email or sms event and sends event data to analytics.
     */
    /*typeAccessEvent(type: string) {

            this.trackingService.typeAccessLoginEvent(type);

            // Google Analytics
            let eventAction: string;
            if(type == "sms") {
                eventAction = "enterLogin:sms";
            } else if (type == "email") {
                eventAction = "enterLogin:email";
            }

            (<any>window).ga('send', 'event', {
                eventCategory: 'Type Access Login Events',
                eventLabel: 'TypeAccessLoginEvent',
                eventAction: eventAction,
                eventValue: 10
            });
    }*/

}
