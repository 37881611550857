import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// third party modules
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { ResponsiveModule } from 'ngx-responsive';
import { ToastrModule } from 'ngx-toastr';
import { NgxPopperModule } from 'ngx-popper';
import { CarouselModule } from 'ngx-bootstrap/carousel';
// Components
import { HeaderComponent } from './header/header.component';
import { FooterWidgetsComponent } from './footer-widgets/footer-widgets.component';
import { UserFaqComponent } from './user-faq/user-faq.component';
import { FooterBottomComponent } from './footer-bottom/footer-bottom.component';
import { PopupComponent } from './popup/popup.component';
import { CountdownComponent } from './countdown/countdown.component';
import { HeaderappComponent } from './headerapp/headerapp.component'
import { TopbarComponent } from './topbar/topbar.component';
import { NopagoInstruccionesComponent } from './nopago-instrucciones/nopago-instrucciones.component';
import { AppLockSessionComponent } from './app-lock-session/app-lock-session.component';
import { CarouselComponent } from './carousel/carousel.component';

/**
 * Shared Module
 */
@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        NgbModule,
        MomentModule,
        ResponsiveModule.forRoot(),
        NgxPopperModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            disableTimeOut: true,
            closeButton: true
        }),
        CarouselModule.forRoot()
    ],
    declarations: [
        HeaderComponent,
        FooterWidgetsComponent,
        FooterBottomComponent,
        UserFaqComponent,
        PopupComponent,
        CountdownComponent,
        HeaderappComponent,
        TopbarComponent,
        NopagoInstruccionesComponent,
        AppLockSessionComponent,
        CarouselComponent
    ],
    exports: [
        HeaderComponent,
        FooterWidgetsComponent,
        FooterBottomComponent,
        UserFaqComponent,
        PopupComponent,
        CountdownComponent,
        HeaderappComponent,
        TopbarComponent,
        NopagoInstruccionesComponent,
        AppLockSessionComponent,
        CarouselComponent
    ]
})
export class SharedModule {

}
