import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

/**
 * SeoService
 *
 * @author Fran Aragon
 */
@Injectable({
    providedIn: 'root'
})
export class SeoService {

    /**
     * @ignore
     */
    constructor(@Inject(DOCUMENT) private _document) {
    }

    /**
     * Set Attributes of meta tags
     */
    setSeoPage( title: string, description: string, keywords: string ) {
        this._document.getElementById('seo-desc').setAttribute('content', description);
        this._document.getElementById('seo-keywords').setAttribute('content', keywords);
        this._document.getElementById('seo-title').innerHTML = title;
    }

}
