import {Component, OnInit, ViewChild } from '@angular/core';
import {LangService} from "./services/lang.service";
import {TrackingService} from "./services/tracking.service";
import {ElementRef} from '@angular/core';

/**
 * App Component
 *
 * @author Fran Aragon
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    /**
     * Reference to element of DOM cookie-law tag
     */
    @ViewChild('cookieLaw') private cookieLawEl: any;

    /**
     * Reference to element of DOM input checkbox cookiesPreferences
     */
    @ViewChild("cookiesPreferences", {read: ElementRef}) cookiesPreferences: ElementRef;

    /**
     * Reference to element of DOM input checkbox cookiesStatistics
     */
    @ViewChild("cookiesStatistics", {read: ElementRef}) cookiesStatistics: ElementRef;

    /**
     * @ignore
     */
    constructor(private lang: LangService,
                private trackingService: TrackingService) {
        this.lang.loadSessionLang();
    }

    /**
     * Action when it is not accepted to capture user events or preferences.
     */
    public dismisscookies(): void {
        this.trackingService.setPermissionTracking('dismiss');
        this.cookieLawEl.dismiss();
    }

    /**
     * Action when it is accepted to capture user events or preferences.
     * It also depends of user selection.
     */
    public acceptcookies(): void {

        if (this.cookiesPreferences.nativeElement.checked) {
            this.trackingService.setPermissionTracking('preferences');
        }

        if (this.cookiesStatistics.nativeElement.checked) {
            this.trackingService.setPermissionTracking('statistics');
        }

        if (this.cookiesStatistics.nativeElement.checked && this.cookiesPreferences.nativeElement.checked) {
            this.trackingService.setPermissionTracking('all');
        }

        if (!this.cookiesStatistics.nativeElement.checked && !this.cookiesPreferences.nativeElement.checked) {
            this.trackingService.setPermissionTracking('dismiss');
        }

        this.cookieLawEl.dismiss();
    }

    /**
     * @ignore
     */
    ngOnInit(){}

}