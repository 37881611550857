/**
 * @ignore
 */
interface RentCarTheme {
    name: string;
}

/**
 * @ignore
 */
export const RentCarThemeStore: RentCarTheme[] = [
    {name: 'PYF'}
];
