import * as fromFine from './fine.actions';
import {FineModel} from './fine.model';

export interface FineState {
    fine: FineModel
}

const initState: FineState = {
    fine: null
};

export function fineReducer(state = initState, action: fromFine.actions): FineState {

    switch (action.type) {

        case fromFine.SET_FINE:
            return {
                fine: {... action.fine}
            };

        case fromFine.UNSET_FINE:
            return {
                fine: null
            };

        default:
            return state;
    }

}
