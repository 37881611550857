/**
 * header for no show loading data in calls
 */
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

export const appConfig = {
    hash_session: "pyf_hash_sess_23934",
    lang_session: "pyf_lang_sess_23934",
    data_session: "pyf_data_sess_23934"
};
