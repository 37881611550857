import {Component, OnInit} from '@angular/core';

/**
 * FooterWidgets Component
 * 
 * @author Fran Aragon
 */
@Component({
    selector: 'app-footer-widgets',
    templateUrl: './footer-widgets.component.html',
    styleUrls: ['./footer-widgets.component.css']
})
export class FooterWidgetsComponent implements OnInit {

    /**
     * @ignore
     */
    constructor() {
    }

    /**
     * @ignore
     */
    ngOnInit() {
    }

}
