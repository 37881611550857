import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {RentCarThemeStore} from "./stores/rentcarthemes.store";

/**
 * @ignore
 */
@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    theme: string;

    constructor(@Inject(DOCUMENT) private _document) {
        this.loadSettings();
    }

    isValidTheme(theme: string) {
        if(RentCarThemeStore.find(ra => ra.name == theme )) {
            return true;
        } else {
            return false;
        }
    }

    saveSettings() {
        localStorage.setItem('theme_ra_pyf', this.theme);
    }

    getTheme() {
        if(localStorage.getItem('theme_ra_pyf')) {
            this.theme = localStorage.getItem('theme_ra_pyf');
            return this.theme;
        }
    }

    loadSettings() {
        if(localStorage.getItem('theme_ra_pyf')) {
            this.theme = localStorage.getItem('theme_ra_pyf');
            //this.applyTheme(this.theme);
        }
    }

    applyTheme( theme: string ) {
        let url: string = `assets/css/app-colors/rentcars/${ theme }.css`;
        //this._document.getElementById('theme-ra-pyf').setAttribute('href', url);

        this.theme = theme;
        this.saveSettings();
    }

}
