import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

/**
 * FooterBottom Component
 *
 * @author Fran Aragon
 */
@Component({
    selector: 'app-footer-bottom',
    templateUrl: './footer-bottom.component.html',
    styleUrls: ['./footer-bottom.component.css']
})
export class FooterBottomComponent implements OnInit {

    /**
     * @ignore
     */
    currentYear: number;
    public target = '_self';
    constructor(private router: Router) {
        this.currentYear = (new Date()).getFullYear();
    }

    /**
     * @ignore
     */
    ngOnInit() {
        if (this.router.url.includes('/app')) {
            this.target = '_blank';
        } else {
            this.target = '_self';
        }
    }

}
