import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-lock-session',
    templateUrl: './app-lock-session.component.html',
    styleUrls: ['./app-lock-session.component.css']
})
export class AppLockSessionComponent implements OnInit {

    @ViewChild('content') private content: any;
    private isOpened: any = false;
    hash: string;
    constructor(private route: ActivatedRoute, private modalConfig: NgbModalConfig, private modalService: NgbModal) {
        this.modalConfig.backdrop = 'static';
        this.route.params.subscribe(params => {
           this.hash = params['hash'];
        });
    }

    ngOnInit() {
    }

    open() {
        if (!this.isOpened) {
            this.modalService.open(this.content);
            this.isOpened = true;
        }
    }
}
