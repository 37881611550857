import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';

import {environment} from '../../environments/environment';

import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

// NGRX
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/app.reducer';
import {UnsetFineAction} from 'src/app/store/fine.actions';

import {appConfig} from 'src/app/config/config';

/**
 * Auth Service
 *
 * @author Fran Aragon
 *
 * Calls to API endpoints
 */
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    /**
     * session token
     */
    private token: string;

    /**
     * session hash
     */
    private hash: string;

    /**
     * key for saving in storage
     */
    private key: string;

    private nameSess: string;
    private dataSess: string;

    /**
     * @ignore
     */
    constructor(private router: Router,
                private route: ActivatedRoute,
                private translate: TranslateService,
                private http: HttpClient,
                private toastr: ToastrService,
                private store: Store<AppState>) {

        this.nameSess = appConfig.hash_session;
        this.dataSess = appConfig.data_session;
        this.loadStorage();
    }

    /**
     * GET Call that is used to obtain new token
     *
     * @returns boolean response
     */
    renuevaToken() {
        const URL_API = environment.URL_API;
        let url = URL_API + '/login/getnewtoken';
        url += '?TOKEN=' + this.token;

        return this.http.get(url)
            .pipe(map((resp: any) => {

                    this.token = resp.token;
                    this.saveStorage();
                    return true;

                }),
                catchError(err => {
                    this.router.navigate([`/app/login/Payyourfines/${ this.hash }`]);
                    const expiredErrMsg: any = this.translate.instant('LoginPage.ExpiredSession');
                    this.toastr.error(expiredErrMsg);
                    return throwError(err);
                })
            );
    }

    /**
     * POST Call that is used to obtain token
     *
     * @param {string} hash
     * @param {string} key
     * @returns POST response
     */
    login(hash: string, key: string) {
        const URL_API = environment.URL_API;
        let url_call = URL_API + `checkcodigo/${ hash }`;

        if (this.islogged()) {
            this.logout();
        }
        let input = new FormData();
        input.append('codigo', key);
        return this.http.post<any>(url_call, input)
            .pipe(map((res: any) => {
                // login successful if there's a jwt token in the response
                if (res && res.TOKEN) {
                    this.key = key;
                    this.token = res.TOKEN;
                    this.hash = hash;
                    this.saveStorage();
                    this.loadStorage();
                }
            }));
    }

    /**
     * Remove session data of local storage
     */
    logout() {
        // remove storage to log out
        localStorage.removeItem(this.nameSess);
        localStorage.removeItem(this.dataSess);
        this.store.dispatch(new UnsetFineAction());
    }

    /**
     * Get Token
     */
    getToken() {
        return this.token;
    }

    /**
     * Get Hash of localstorage or route parameter
     */
    getHash() {
        if (localStorage.getItem(this.nameSess)) {
            this.hash = JSON.parse(atob(localStorage.getItem(this.nameSess))).hash;
        } else {
            this.route.params.subscribe(params => {
                this.hash = params['hash'];
            });
        }
        return this.hash;
    }

    /**
     * Load session data
     */
    loadStorage() {
        if (localStorage.getItem(this.nameSess)) {
            this.token = JSON.parse(atob(localStorage.getItem(this.nameSess))).token;
            this.hash = JSON.parse(atob(localStorage.getItem(this.nameSess))).hash;
        } else {
            this.token = '';
            this.hash = '';
        }
        if (localStorage.getItem('codigo')) {
            this.key = localStorage.getItem('codigo');
        } else {
            this.key = '';
        }
        this.saveStorage();
    }

    /**
     * Save session data in local storage
     */
    saveStorage() {
        localStorage.setItem('codigo', this.key);
        let ses = btoa(JSON.stringify({hash: this.hash, token: this.token}));
        // store encoded hash and jwt token in storages to keep user logged in between page refreshes
        localStorage.setItem(this.nameSess, ses);
    }

    /**
     * Check if user is logged
     *
     * @returns boolean
     */
    islogged() {
        this.loadStorage();
        return (this.token.length > 5 && this.hash.length > 5) ? true : false;
    }
}

