import { Injectable } from '@angular/core';

/**
 * RedirectService
 *
 * @author Fran Aragon
 *
 * Create and send an automatic form with parameters
 */
@Injectable()
export class RedirectService {

    /**
     * @ignore
     */
    constructor() { }

    /**
     * Create and send an automatic form with parameters
     *
     * @param {Object} obj
     * @param {string} url
     */
    post(obj,url) {
        var mapForm = document.createElement("form");
        mapForm.method = "POST"; // or "post" if appropriate
        mapForm.action = url;
        mapForm.enctype = "application/x-www-form-urlencoded";
        Object.keys(obj).forEach(function(param){
            var mapInput = document.createElement("input");
            mapInput.type = "hidden";
            mapInput.name = param;
            mapInput.setAttribute("value", obj[param]);
            mapForm.appendChild(mapInput);
        });
        document.body.appendChild(mapForm);
        mapForm.submit();
    }

}