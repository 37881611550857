import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// third party modules
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieLawModule} from 'angular2-cookie-law';
import {CookieService} from 'ngx-cookie-service';
import {ResponsiveModule} from 'ngx-responsive';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {ToastrModule} from 'ngx-toastr';
import {NgxPopperModule} from 'ngx-popper';

// NGRX
import {StoreModule} from '@ngrx/store';
import {appReducers} from './app.reducer';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

// Environments
import {environment} from '../environments/environment';

// app modules
import {ServiceModule} from './services/service.module';
import {SharedModule} from './shared/shared.module';
import {NgxCaptchaModule} from './libs/ngx-captcha';

/**
 * AoT requires an exported function for factories
 */
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

// Routes
import {APP_ROUTES} from './app.routes';

// Components
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {ApppagesComponent} from "./apppages/apppages.component";
import {SitepagesComponent} from "./sitepages/sitepages.component";
import {TinyComponent} from './tiny/tiny.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {NotFoundComponent} from './notfound/notfound.component';

/**
 * App Module
 */
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ApppagesComponent,
        SitepagesComponent,
        TinyComponent,
        NotFoundComponent
    ],
    exports: [
        FormsModule
    ],
    imports: [
        StoreModule.forRoot(appReducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        ServiceModule,
        HttpClientModule,
        BrowserModule,
        FormsModule,
        APP_ROUTES,
        CookieLawModule,
        SharedModule,
        ResponsiveModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DeviceDetectorModule.forRoot(),
        NgxCaptchaModule,
        NgxPopperModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            disableTimeOut: false,
            closeButton: true,
            progressBar: true
        }),
        NgbDropdownModule
    ],
    providers: [
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
