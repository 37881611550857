import {Component, HostListener, OnInit, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import { WINDOW } from "../../services/window.service";
import {LangService} from "../../services/lang.service";
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";
import {AuthService} from "../../services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {ThemeService} from "../../services/theme.service";
import {TrackingService} from "../../services/tracking.service";
import {ToastrService} from 'ngx-toastr';

/**
 * @ignore
 */
@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

    private hash: string;

    public show: boolean = false;
    public collapse:string = "closed";
    public currentLang: string;

    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    constructor(private lang: LangService,
                @Inject(DOCUMENT) private _document,
                @Inject(WINDOW) private window: Window,
                private router: Router,
                private authService: AuthService,
                private translate: TranslateService,
                private themeService: ThemeService,
                private trackingService: TrackingService,
                private location: Location,
                private toastr: ToastrService) {

        this.hash = this.authService.getHash();
    }

    // change class on scroll
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let main_navbar = this._document.getElementById('main-navbar');
        const offset = this.window.pageYOffset || this._document.documentElement.scrollTop || this._document.body.scrollTop || 0;
        if (offset > 0){
            main_navbar.classList.add("thicknesstop");
        } else {
            main_navbar.classList.remove("thicknesstop");
        }
    }

    toggleCollapse() {
        this.show = !this.show;
        this.collapse = this.collapse == "open" ? 'closed' : 'open';
    }

    logout(){
        this.authService.logout();
        this.router.navigate([`/app/login/pyf/${ this.hash }`]);
        const infoMsg: any = this.translate.instant('InfoMessages.Info1');
        this.toastr.info(infoMsg);
    }

    ngOnInit() {
        this.currentLang = this.lang.getLang();

        // Scroll to top on Route Change
        this.location.subscribe((ev:PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((ev:any) => {
            if (ev instanceof NavigationStart) {
                if (ev.url != this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (ev instanceof NavigationEnd) {
                if (ev.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });
    }

    switchLanguage(lang: string) {
        this.lang.switchLang(lang);
        this.currentLang = this.lang.getLang();
        localStorage.setItem('language', lang);
        this.window.location.reload();
    }

    // tracking de usuario
    myEvent(event) {
        if(this.trackingService.getPermissionTracking() == 'all' || this.trackingService.getPermissionTracking() == 'statistics'){

            this.trackingService.setHash(this.hash);
            this.trackingService.actionEvent(event, 'LOGOUT');
        }

        // Google Analytics
        let idElement: string = (event.target as Element).id;
        let typeEvent: string = event.type;
        let eventAction: string = "idelement: " + idElement + " - typeEvent: " + typeEvent;
        // (<any>window).ga('send', 'event', {
        //     eventCategory: 'Fine Page Events',
        //     eventLabel: 'FinePageEvents',
        //     eventAction: eventAction,
        //     eventValue: 10
        // });

    }

}
