import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthService} from "./auth.service";

import {InterceptorSkipHeader} from "../config/config";
import {environment} from "../../environments/environment";
import {LangService} from "./lang.service";

/**
 * Api Service
 *
 * @author Fran Aragon
 *
 * Calls to API endpoints
 */
@Injectable({
    providedIn: 'root'
})
export class ApiService {

    /**
     * @ignore
     */
    constructor(private http: HttpClient,
                private authService: AuthService,
                private langService: LangService) {
    }

    /**
     * POST Call that is used to obtain the data of the fine
     * Url endpoint example: this.apiService.getFineData('hash', 'expediente')
     * @param {string} hash
     * @param {string} file
     * @returns GET response
     */
    getFineData(hash: string, file?: string) {
        const URL_API = environment.URL_API;
        const headers = new HttpHeaders().set('token', this.authService.getToken());
        let body = {};
        if (!file || file === 'undefined') {
            body = {'codigo': localStorage.getItem('codigo')};
        } else {
            body = {'codigo': localStorage.getItem('codigo'), 'expediente': file};
        }
        return this.http.post(URL_API + 'getmulta/' + hash, body, {headers});
    }

    /**
     * GET Call that is used to request payment tpv parameters with language code in headers
     *
     * @param {string} url Url endpoint example: this.apiService.initPayProcess(`${ this.hash }/iniciapago`)
     * @returns GET response
     */
    initPayProcess(url: string) {
        const URL_API = environment.URL_API;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append("TOKEN", this.authService.getToken());
        headers = headers.append("consumerlang", this.langService.getLangTPV());
        headers = headers.append(InterceptorSkipHeader, '');
        return this.http.get<any>(URL_API + url, {headers});
    }

    /**
     * GET Call that is used to request payment tpv parameters with query parameter (language code)
     *
     * @param {string} url Url endpoint example: this.apiService.initPayProcess(`${ this.hash }/iniciapago`)
     * @param {string} userlang optional , default 001
     * @returns GET response
     */
    initPayProcessParam(url: string, userlang?: string) {
        const URL_API = environment.URL_API;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append("TOKEN", this.authService.getToken());
        headers = headers.append(InterceptorSkipHeader, '');

        let params;
        if (userlang) {
            params = new HttpParams().set("consumerlang", userlang); //Create new HttpParams
        } else {
            params = new HttpParams().set("consumerlang", "001"); //Create new HttpParams
        }

        return this.http.get<any>(URL_API + url, {headers: headers, params: params});
    }

    /**
     * GET Call that is used to request if payment has been processed and generated proof of payment
     *
     * @param {string} url Url endpoint example: this.apiService.processingPay(`paying/${ this.hash }`)
     * @returns GET response
     */
    processingPay(url: string) {
        const URL_API = environment.URL_API;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append("TOKEN", this.authService.getToken());
        headers = headers.append(InterceptorSkipHeader, '');
        return this.http.get<any>(URL_API + url, {headers});
    }

    /**
     * GET Call that is used to obtain url of the shortener link
     *
     * @param {string} param of shortened url example: this.apiService.getUrlTiny(this.tiny)
     * @returns GET response
     */
    getUrlTiny(tiny: string) {
        const URL_API_TINY = environment.URL_API_TINY;
        return this.http.get(URL_API_TINY + tiny);
    }

}
