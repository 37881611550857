import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TrackingService} from '../../services/tracking.service';
import {AuthService} from '../../services/auth.service';

/**
 * NopagoInstruccionesComponent
 *
 * @author Juan Cubero
 *
 * Used to show INFO pop up instructions
 */
@Component({
    selector: 'app-nopago-instrucciones',
    templateUrl: './nopago-instrucciones.component.html',
    styleUrls: ['./nopago-instrucciones.component.css']
})
export class NopagoInstruccionesComponent implements OnInit, AfterViewInit {

    /**
     * Session hash
     */
    private hash: string;

    /**
     * Data values of the fine to use in multi-language texts
     */
    public _data2nopay: any;

    /**
     * Element Reference of Template
     */
    @ViewChild('popupmodal') popupmodal: ElementRef;

    constructor(private modalService: NgbModal,
                private trackingService: TrackingService,
                private authService: AuthService) {
        this.hash = this.authService.getHash();
    }

    /**
     * Configures input data obtained of viewfine parent component to display values in multi-language text
     */
    @Input() set data2nopay(value: any[]) {
        this._data2nopay = value;
    }

    /**
     * @ignore
     */
    ngOnInit() { }

    /**
     * @ignore
     */
    ngAfterViewInit() {
        setTimeout(() => this.forceOpen());
    }

    /**
     * Open modal
     */
    forceOpen() {
        if (this._data2nopay.show === true && this.popupmodal !== undefined) {
            this.modalService.open(this.popupmodal, {size: 'lg', centered: true, windowClass: 'animated fadeIn'});
            this._data2nopay.show = false;
        }
    }

    /**
     * @param modalref
     * @param event
     */
    open(modalref, event) {
        this.modalService.open(modalref, {size: 'lg', centered: true, windowClass: 'animated fadeIn'});
        // this.myEvent(event);
    }

    /**
     * Captures component event and sends event data to analytics.
     */
    myEvent(event) {
        if (this.trackingService.getPermissionTracking() == 'all' || this.trackingService.getPermissionTracking() == 'statistics') {

            this.trackingService.setHash(this.hash);

            this.trackingService.actionEvent(event, 'SHOW_POPUP');

            // Google Analytics
            let idElement: string = (event.target as Element).id;
            let typeEvent: string = event.type;
            let eventAction: string = "idelement: " + idElement + " - typeEvent: " + typeEvent;
            // (<any>window).ga('send', 'event', {
            //     eventCategory: 'Popup Events',
            //     eventLabel: 'PopupEvents',
            //     eventAction: eventAction,
            //     eventValue: 10
            // });

        }

    }
}
