import {Component, HostListener, OnInit, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {WINDOW} from "../../services/window.service";
import {LangService} from "../../services/lang.service";
import {Router, NavigationStart, NavigationEnd} from '@angular/router';
import {Location, PopStateEvent} from "@angular/common";

/**
 * Header Component
 *
 * Header component used in landing.
 */
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    /**
     * Used to toggleCollapse method for nav-bar
     */
    public show: boolean = false;

    /**
     * Used to toggleCollapse method for nav-bar
     */
    public collapse: string = "closed";

    /**
     * Store the language used by the user currently
     */
    public currentLang: string;

    /**
     * Used to Scroll to top on Route Change
     */
    private lastPoppedUrl: string;

    /**
     * Used to Scroll to top on Route Change
     */
    private yScrollStack: number[] = [];

    constructor(private lang: LangService,
                @Inject(DOCUMENT) private _document,
                @Inject(WINDOW) private window: Window,
                private router: Router,
                private location: Location) {
    }

    /**
     * To change css class on scroll
     */
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let main_navbar = this._document.getElementById('main-navbar');
        const offset = this.window.pageYOffset || this._document.documentElement.scrollTop || this._document.body.scrollTop || 0;
        if (offset > 0) {
            main_navbar.classList.add("thickness");
        } else {
            main_navbar.classList.remove("thickness");
        }
    }

    /**
     * Used by button toggleCollapse for nav-bar
     */
    toggleCollapse() {
        this.show = !this.show;
        this.collapse = this.collapse == "open" ? 'closed' : 'open';
    }

    /**
     * Set currentLang using LangService and Scroll to top on Route Change
     */
    ngOnInit() {
        this.currentLang = this.lang.getLang();

        // Scroll to top on Route Change
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((ev: any) => {
            if (ev instanceof NavigationStart) {
                if (ev.url != this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (ev instanceof NavigationEnd) {
                if (ev.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });
    }

    /**
     * To change/set lang using select header
     */
    switchLanguage(lang: string) {
        this.lang.switchLang(lang);
        this.currentLang = this.lang.getLang();
        localStorage.setItem('language', lang);
        this.window.location.reload();
    }

}

