import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../auth.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from 'ngx-toastr';

/**
 * AuthGuard
 *
 * @author Fran Aragon
 *
 * Using like a middleware method to protect access routes
 */
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    /**
     * hash
     */
    private hash: string;

    /**
     * @ignore
     */
    constructor(private router: Router,
                private authService: AuthService,
                private translate: TranslateService,
                private toastr: ToastrService) {
        this.hash = this.authService.getHash();
    }

    /**
     * Using like a middleware method to protect access routes
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns boolean
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.authService.islogged()) {
            // logged in so return true
            return true;
        } else {
            if (this.hash) {
                this.router.navigate([`/app/login/pyf/${this.hash}`]);
                return false;
            } else {

                let url_split = state.url.split("/");
                this.hash = url_split[url_split.length - 1];

                this.router.navigate([`/app/login/pyf/${this.hash}`]);
                const expiredErrMsg: any = this.translate.instant('ErrorsMessages.Err401');
                this.toastr.error(expiredErrMsg);
                return false;
            }
        }

    }

}
