/**
 * Lang ISO interface
 */
interface LangISO {
    lang: string;
    isoCode: string;
}

/**
 * List of Code languages of tpv
 */
export const LangISOStore: LangISO[] = [
    {lang: 'es', isoCode: "001"},
    {lang: 'en', isoCode: "002"}
    // {lang: 'fr', isoCode: "004"},
    // {lang: 'it', isoCode: "007"},
    // {lang: 'pt', isoCode: "009"},
    // {lang: 'de', isoCode: "005"},
    // {lang: 'ru', isoCode: "643"},
    // {lang: 'nl', isoCode: "006"}
];
