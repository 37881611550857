import {Injectable} from '@angular/core';

/**
 * PdfdataService
 *
 * @author Fran Aragon
 */
@Injectable({
    providedIn: 'root'
})
export class PdfdataService {

    private pdfineURL: any;
    private pdfinepaidURL: any;
    private pdfs: any = [];

    /**
     * @ignore
     */
    constructor() {
    }

    /**
     * Set URL for type of document pdf
     *
     * @param {string} encodedFile
     * @param {string} type The type of document "fine" "paidfine"
     */
    public setURLPdf(encodedFile: string, type: string) {

        if(encodedFile) {
            let pdfine = atob(encodedFile);

            let array = new Uint8Array(pdfine.length)
            for (let i = 0; i < pdfine.length; i++) {
                array[i] = pdfine.charCodeAt(i);
            }
            let filePdf = new Blob([array], {type: 'application/pdf'});
            this.pdfs[type] = URL.createObjectURL(filePdf);

        } else {
           this.pdfs[type] = null;
        }

    }

    /**
     * Get URL for type of document pdf
     *
     * @param {string} type The type of document "fine" "paidfine"
     * @returns {any} url of pdf
     */
    public getURLPdf(type: string) {
      return this.pdfs[type];
    }

}
