import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 * LoaderService
 *
 * @author Fran Aragon
 *
 * Intercepts http calls to show loading
 */
@Injectable()
export class LoaderService {
    public isLoading = new BehaviorSubject(false);

    /**
     * @ignore
     */
    constructor() {
    }
}