import {Component, OnInit} from '@angular/core';

/**
 * Apppages Component
 *
 * @author Fran Aragon
 *
 * Used to show pop ups with management options
 */
@Component({
    selector: 'app-apppages',
    templateUrl: './apppages.component.html',
    styles: []
})
export class ApppagesComponent implements OnInit{


    /**
     * @ignore
     */
    constructor() {
    }

    /**
     * On init component, call chat switcher
     */
    ngOnInit() {
    }

}
