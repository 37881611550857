import {Component, Inject, OnInit, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";

import {environment} from "../../environments/environment";
import {TrackingService} from "../services/tracking.service";
import {NavigationEnd} from '@angular/router';

/**
 * Tiny Component
 *
 * @author Fran Aragon
 *
 * Used for shortened urls that backend redirects.
 */
@Component({
    selector: 'app-tiny',
    templateUrl: './tiny.component.html',
    styleUrls: []
})
export class TinyComponent implements OnInit, AfterViewInit {

    /**
     * Route parameter pyf/:tinyurl
     */
    private tiny: string;

    /**
     * @ignore
     */
    constructor(private route: ActivatedRoute,
                @Inject(DOCUMENT) private _document,
                private trackingService: TrackingService,
                private router: Router) {
        this.route.params.subscribe(params => {
            this.tiny = params['tinyurl'];
        });
    }

    /**
     * On init component, it is redirected to the route returned by the backend route shortener.
     */
    ngOnInit() {

        const URL_API_TINY = environment.URL_API_TINY;
        location.href = URL_API_TINY+`${this.tiny}`;

        // redirect
        this._document.location.href = URL_API_TINY+`${this.tiny}`;
    }

    /**
     * Captures access sms event and sends event data to analytics.
     */
    smsEvent() {

        this.trackingService.setHash(this.tiny);

        this.trackingService.smsEvent();

        // Google Analytics
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // Google Analytics
                // (<any>window).ga('send', 'event', {
                //     eventCategory: 'SMS Access Login',
                //     eventLabel: 'SMSAccessLogin',
                //     eventAction: 'enterLogin:sms',
                //     eventValue: 10
                // });
            }
        });
    }

    ngAfterViewInit(): void {
        this.smsEvent();
    }

}
