import {Component, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TrackingService} from "../../services/tracking.service";
import {AuthService} from "../../services/auth.service";

/**
 * User Faq Component
 *
 * @author Fran Aragon
 *
 * Used in user area where information of the fine is displayed.
 */
@Component({
    selector: 'app-user-faq',
    templateUrl: './user-faq.component.html',
    styleUrls: ['./user-faq.component.css']
})
export class UserFaqComponent implements OnInit {

    /**
     * session hash
     */
    private hash: string;

    /**
     * @ignore
     */
    constructor(private modalService: NgbModal,
                private trackingService: TrackingService,
                private authService: AuthService) {

        this.hash = this.authService.getHash();
    }

    /**
     * Open modal and call tracking method
     */
    openFaq(faquser, event) {
        // this.myEvent(event);
        this.modalService.open(faquser, { size: 'lg', centered: true });
    }

    /**
     * @ignore
     */
    ngOnInit() {}

    /**
     * Captures component event and sends event data to analytics.
     */
    myEvent(event) {
        if(this.trackingService.getPermissionTracking() == 'all' || this.trackingService.getPermissionTracking() == 'statistics'){

            this.trackingService.setHash(this.hash);

            this.trackingService.actionEvent(event, 'SHOW_FAQ');

            let idElement: string = (event.target as Element).id;
            let typeEvent: string = event.type;
            let eventAction: string = "idelement: "+idElement+" - typeEvent: "+typeEvent;
            // (<any>window).ga('send', 'event', {
            //     eventCategory: 'User Faq Events',
            //     eventLabel: 'UserFaqEvents',
            //     eventAction: eventAction,
            //     eventValue: 10
            // });
        }

    }

}
