import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {InterceptorSkipHeader} from '../config/config';
import {environment} from '../../environments/environment';

import {AuthService} from './auth.service';
import {CookieService} from 'ngx-cookie-service';

import * as moment from 'moment-timezone';

/**
 * TrackingService
 *
 * @author Fran Aragon
 */
@Injectable({
    providedIn: 'root'
})
export class TrackingService {

    /**
     * Type of permission tracking
     */
    private permission_tc: string;
    private hash = "";


    /**
     * @ignore
     */
    constructor(private http: HttpClient,
                private authService: AuthService,
                private cookieService: CookieService) {
        this.loadPermissionTracking();
    }

    /**
     * Set type permission tracking data
     */
    setPermissionTracking(permission: string) {
        this.permission_tc = permission;
        this.savePermissionTracking();
    }

    /**
     * Save type permission tracking data
     */
    savePermissionTracking() {
        localStorage.setItem('permission_pyf_tc', this.permission_tc);
    }

    /**
     * Get type permission tracking data
     */
    getPermissionTracking() {
        return this.permission_tc;
    }

    /**
     * Load type permission tracking data
     */
    loadPermissionTracking() {
        if (localStorage.getItem('permission_pyf_tc')) {
            this.permission_tc = localStorage.getItem('permission_pyf_tc');
        } else {
            // borrar cookie
            this.cookieService.delete('pyf_cookielaw', '/');
            this.permission_tc = 'waiting';
        }
        this.savePermissionTracking();
    }

    /**
     * User Action event
     *
     * @param {any} event Dom element reference
     * @param type
     */
    actionEvent(event: any, type: string) {
        let elementId: string = (event.target as Element).id;
        let data = {
            'type': type,
            'info': elementId,
            'datetime': this.getTimeDateNow()
        };

        this.sendTracking(data).subscribe();
    }

    /**
     * Return TPV event
     *
     * @param {string} event
     * @param type
     */
    returnTPVEvent(event: string, type: string) {
        let data = {
            'type': type,
            'info': event,
            'datetime': this.getTimeDateNow()
        };

        this.sendTracking(data).subscribe();
    }

    /**
     * @deprecated
     *
     *  Type Access Login - sms or email
     *
     *  @param {string} type - 'sms' or 'email'
     */
    typeAccessLoginEvent(type: string) {
        let info: string;
        if (type == 'sms') {
            info = 'enterLogin:sms';
        } else if (type == 'email') {
            info = 'enterLogin:email';
        }

        let data = {
            'type': 'LOGIN',
            'info': info,
            'datetime': this.getTimeDateNow()
        };

        this.sendTracking(data).subscribe();
    }

    /**
     *  Use sms link event
     */
    smsEvent() {

        let data = {
            'type': 'LOGIN',
            'info': 'enterLogin:sms',
            'datetime': this.getTimeDateNow()
        };

        this.sendTracking(data).subscribe();
    }

    /**
     *  Init Login event
     */
    initLoginEvent() {

        let data = {
            'type': 'LOGIN',
            'info': 'enterLogin',
            'datetime': this.getTimeDateNow()
        };

        this.sendTracking(data).subscribe();
    }

    /**
     *  Error event
     *
     *  @param {string} status
     *  @param {string} message
     */
    errorEvent(status: string, message: string) {
        let data = {
            'type': 'ERROR',
            'info': status,
            'datetime': this.getTimeDateNow()
        };

        this.sendTracking(data).subscribe();
    }

    /**
     * Send tracking data
     *
     * @param {any} data
     */
    sendTracking(data: any) {
        const hash = (this.hash !== "") ? this.hash : this.authService.getHash();
        const token  = this.isLogged() ? this.authService.getToken() : "";
        const sendData = {
            'hash': hash,
            'token': token,
            'dataTracking': data
        };

        const URL_API = environment.URL_API;
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        headers.set('TOKEN', token);
        return this.http.post<any>(URL_API + 'tracking/', JSON.stringify(sendData), {headers});
    }

    /**
     * Set hash in the tracking
     * @param hash
     */
    setHash(hash: string) {
        this.hash = hash;
    }

    /**
     * Check if user is logged with route hash is the same of session hash
     */
    isLogged(){
        return this.authService.getToken() && this.hash === this.authService.getHash();
    }

    /**
     * Return locale date time
     */
    getTimeDateNow(){
        return new Date(moment().tz('Europe/Madrid').format()).toLocaleString();
    }


}
