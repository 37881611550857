import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './login/login.component';
import {SitepagesComponent} from "./sitepages/sitepages.component";
import {ApppagesComponent} from "./apppages/apppages.component";
import {TinyComponent} from "./tiny/tiny.component";
import {NotFoundComponent} from './notfound/notfound.component';

/**
 * App routes
 *
 * @author Fran Aragon
 */
const appRoutes: Routes = [
    {
        path: '',
        component: SitepagesComponent,
        loadChildren: './sitepages/sitepages.module#SitePagesModule'
    },
    {path: 'pyf/:tinyurl', component: TinyComponent},
    {path: 'app/login/pyf/:hash', component: LoginComponent},
    {
        path: '',
        component: ApppagesComponent,
        loadChildren: './apppages/apppages.module#AppPagesModule'
    },
    {path: 'notfound', component: NotFoundComponent },
    {path: '**', redirectTo: 'notfound'},
    {path: '', redirectTo: '/home', pathMatch: 'full'}

];

/**
 * App routes
 */
export const APP_ROUTES = RouterModule.forRoot(appRoutes, {useHash: false});
