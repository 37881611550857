import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
    ApiService,
    AuthService,
    TrackingService,
    SeoService,
    LangService,
    AuthGuard,
    VerifyTokenGuard,
    LoaderInterceptor,
    LoaderService,
    WINDOW_PROVIDERS,
    RedirectService,
    RoutingStateService,
    TypeaccessloginGuard,
    PdfdataService
} from './service.index';

/**
 * ServiceModule
 */
@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        ApiService,
        AuthService,
        TrackingService,
        SeoService,
        LangService,
        AuthGuard,
        TypeaccessloginGuard,
        VerifyTokenGuard,
        LoaderService,
        WINDOW_PROVIDERS,
        RoutingStateService,
        RedirectService,
        { provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        PdfdataService
    ],
    declarations: []
})
export class ServiceModule { }
