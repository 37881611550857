import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../auth.service";
import {ThemeService} from "../theme.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from 'ngx-toastr';

/**
 * VerifyTokenGuard
 *
 * @author Fran Aragon
 *
 * Used like a middleware method to protect access routes when session expires
 */
@Injectable({
    providedIn: 'root'
})
export class VerifyTokenGuard implements CanActivate {

    private hash: string;

    /**
     * @ignore
     */
    constructor(public authService: AuthService,
                public router: Router,
                private themeService: ThemeService,
                private translate: TranslateService,
                private toastr: ToastrService) {

        this.hash = this.authService.getHash();
    }

    /**
     * Used like a middleware method to protect access routes when session expires, it check expires date in payload of token
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns boolean
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

        let token = this.authService.getToken();
        if (token && token != '') {

            let payload = JSON.parse(atob(token.split('.')[1]));

            let expired = this.isExpired(payload.exp);

            if (expired) {
                this.router.navigate([`/app/login/pyf/${this.hash}`], {queryParams: {returnUrl: state.url}});
                this.authService.logout();
                const expiredErrMsg: any = this.translate.instant('LoginPage.ExpiredSession');
                this.toastr.error(expiredErrMsg);
                return false;
            }

            return true;
        } else {

            let url_split = state.url.split("/");
            this.hash = url_split[url_split.length - 1];

            this.router.navigate([`/app/login/pyf/${this.hash}`], {queryParams: {returnUrl: state.url}});
            this.authService.logout();
            const expiredErrMsg: any = this.translate.instant('LoginPage.ExpiredSession');
            this.toastr.error(expiredErrMsg);

            return false;
        }

        //return this.verifyRenovate(payload.exp, state);
    }

    /**
     * Used to renovate token before session expires, it check expires date in payload of token
     *
     * @param {number} dateExp
     * @param {RouterStateSnapshot} state
     * @returns boolean
     */
    verifyRenovate(dateExp: number, state: RouterStateSnapshot): Promise<boolean> {

        return new Promise((resolve, reject) => {

            let tokenExp = new Date(dateExp * 1000);
            let now = new Date();

            now.setTime(now.getTime() + (1 * 60 * 60 * 1000)); // 1 hour after

            if (tokenExp.getTime() > now.getTime()) {
                resolve(true);
            } else {

                this.authService.renuevaToken()
                    .subscribe(() => {
                        resolve(true);
                    }, () => {
                        reject(false);
                        this.router.navigate([`/app/login/pyf/${this.hash}`], {queryParams: {returnUrl: state.url}});
                        const expiredErrMsg: any = this.translate.instant('LoginPage.ExpiredSession');
                        this.toastr.error(expiredErrMsg);
                    })

            }

        });

    }

    /**
     * Used to verify Date expiration of token date in payload of token
     *
     * @param {number} dateExp
     * @returns boolean
     */
    isExpired(dateExp: number) {
        let now = new Date().getTime() / 1000;

        if (dateExp < now) {
            return true;
        } else {
            return false;
        }

    }

}
