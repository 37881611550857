import {Component, HostListener, OnInit, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {WINDOW} from "../../services/window.service";
import {LangService} from "../../services/lang.service";
import {Router, NavigationStart, NavigationEnd} from '@angular/router';
import {Location, PopStateEvent} from "@angular/common";
import {AuthService} from "../../services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {ThemeService} from "../../services/theme.service";
import {TrackingService} from "../../services/tracking.service";
import {ToastrService} from 'ngx-toastr';

/**
 * Header App Component
 *
 * @author Fran Aragon
 *
 * Header component used in user area.
 */
@Component({
    selector: 'app-headerapp',
    templateUrl: './headerapp.component.html',
    styleUrls: ['./headerapp.component.css']
})
export class HeaderappComponent implements OnInit {

    /**
     * Session hash
     */
    private hash: string;

    /**
     * Used to toggleCollapse method for nav-bar
     */
    public show: boolean = false;

    /**
     * Used to toggleCollapse method for nav-bar
     */
    public collapse: string = "closed";

    /**
     * Store the language used by the user currently
     */
    public currentLang: string;

    /**
     * Used to Scroll to top on Route Change
     */
    private lastPoppedUrl: string;

    /**
     * Used to Scroll to top on Route Change
     */
    private yScrollStack: number[] = [];

    constructor(private lang: LangService,
                @Inject(DOCUMENT) private _document,
                @Inject(WINDOW) private window: Window,
                private router: Router,
                private authService: AuthService,
                private translate: TranslateService,
                private themeService: ThemeService,
                private trackingService: TrackingService,
                private location: Location,
                private toastr: ToastrService) {

        this.hash = this.authService.getHash();
    }

    /**
     * To change css class on scroll
     */
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let main_navbar = this._document.getElementById('main-navbar');
        const offset = this.window.pageYOffset || this._document.documentElement.scrollTop || this._document.body.scrollTop || 0;
        if (offset > 0) {
            main_navbar.classList.add("thicknesstop");
        } else {
            main_navbar.classList.remove("thicknesstop");
        }
    }

    /**
     * Used by button toggleCollapse for nav-bar
     */
    toggleCollapse() {
        this.show = !this.show;
        this.collapse = this.collapse == "open" ? 'closed' : 'open';
    }

    /**
     * Close session and redirect to login page
     */
    logout(event) {
        // this.myEvent(event);
        this.authService.logout();
        this.router.navigate([`/app/login/pyf/${ this.hash }`]);
        const infoMsg: any = this.translate.instant('InfoMessages.Info1');
        this.toastr.info(infoMsg);
    }

    /**
     * Set currentLang using LangService and Scroll to top on Route Change
     */
    ngOnInit() {
        this.currentLang = this.lang.getLang();

        // Scroll to top on Route Change
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((ev: any) => {
            if (ev instanceof NavigationStart) {
                if (ev.url != this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (ev instanceof NavigationEnd) {
                if (ev.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });
    }

    /**
     * To change/set lang using select header
     */
    switchLanguage(lang: string) {
        this.lang.switchLang(lang);
        this.currentLang = this.lang.getLang();
        localStorage.setItem('language', lang);
        this.window.location.reload();
        this.window.location.reload();
    }

    /**
     * Captures component event and sends event data to analytics.
     */
    myEvent(event) {
        if(this.trackingService.getPermissionTracking() == 'all' || this.trackingService.getPermissionTracking() == 'statistics'){

            this.trackingService.setHash(this.hash);

            this.trackingService.actionEvent(event, 'LOGOUT');

            let idElement: string = (event.target as Element).id;
            let typeEvent: string = event.type;
            let eventAction: string = "idelement: "+idElement+" - typeEvent: "+typeEvent;
            // (<any>window).ga('send', 'event', {
            //     eventCategory: 'Header User Area Events',
            //     eventLabel: 'HeaderUserAreaEvents',
            //     eventAction: eventAction,
            //     eventValue: 10
            // });
        }

    }

}

