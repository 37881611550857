import {Component, Input, OnInit} from '@angular/core';

/**
 * Countdown Component
 * 
 * @author Fran Aragon
 *
 * Used to show the remaining time to pay a fine
 */
@Component({
    selector: 'app-countdown',
    templateUrl: './countdown.component.html',
    styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit {

    /**
     * Store date obtained of viewfine component
     */
    @Input() fechaFinEnlace: any;

    /**
     * Store date obtained of viewfine component
     */
    @Input() fechaHoraServidor: any;

    public timeOut: boolean =  false;
    public diffDays: number;
    public diffHrs: number;
    public diffMins: number;

    /**
     * @ignore
     */
    constructor() {}

    /**
     * Set dates and convert to days hours and minutes
     */
    ngOnInit() {

        let dateExpires = new Date(this.fechaFinEnlace.replace(' ', 'T'));
        let nowdate = new Date(this.fechaHoraServidor.replace(' ', 'T'));

        if(nowdate.getTime() > dateExpires.getTime()) {
            this.timeOut = true;
        } else {
            let diffMs = Math.abs(dateExpires.getTime() - nowdate.getTime() );

            this.diffDays = Math.floor(diffMs / 86400000); // days
            this.diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
            this.diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        }


    }
}
