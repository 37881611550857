import {Component, OnInit} from '@angular/core';
import {SeoService} from '../../app/services/seo.service';
import {TranslateService} from '@ngx-translate/core';


/**
 * After the payment fine, to show error in payment process
 *
 * @author Fran Aragon
 */
@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
    styles: []
})
export class NotFoundComponent implements OnInit {

    /**
     * @ignore
     */
    private seo_content;

    /**
     * @ignore
     */
    constructor(private seoService: SeoService,
                private translate: TranslateService) {

        const SeoTitle: any = this.translate.instant('Seo.Title');
        const SeoDesc: any = this.translate.instant('Seo.Desc');
        const SeoKeywords: any = this.translate.instant('Seo.Keywords');

        this.seo_content = {
            'title' : SeoTitle,
            'desc' : SeoDesc,
            'keywords' : SeoKeywords
        };

    }

    /**
     * @ignore
     */
    ngOnInit() {
    }

}
